var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.products
    ? _c(
        "v-container",
        { staticClass: "product-recall-container", on: { click: _vm.clicked } },
        [
          _c("h1", [_vm._v("Richiamo prodotti")]),
          _c(
            "v-alert",
            {
              staticClass: "warning-alert text-center",
              attrs: { color: "primary" }
            },
            [
              _c("h3", { staticClass: "white--text" }, [_vm._v("ATTENZIONE!")]),
              _c("p", { staticClass: "white--text font-weight-bold" }, [
                _vm._v(
                  " Qualora aveste acquistato uno di questi prodotti, con il numero di lotto/scadenza indicati, vi invitiamo cortesemente a riportarlo presso il punto vendita d'acquisto o a seguire le indicazioni riportate. "
                )
              ])
            ]
          ),
          _vm._l(_vm.products, function(product) {
            return _c(
              "v-row",
              {
                key: product.id,
                staticClass:
                  "product-recall-row ma-1 mb-6 grey lighten-1 rounded-lg rounded-bl-0"
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pa-6 rounded-tl-lg white",
                    attrs: { col: "12", sm: "3" }
                  },
                  [_c("v-img", { attrs: { src: product.featured_image_url } })],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-6", attrs: { col: "12", sm: "9" } },
                  [
                    _c("h3", { staticClass: "primary--text" }, [
                      _vm._v(_vm._s(product.title.rendered))
                    ]),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(product.content.rendered) }
                    }),
                    product.file_pdf
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            attrs: {
                              large: "",
                              elevation: "0",
                              href: product.file_pdf.guid,
                              target: "_system"
                            }
                          },
                          [_vm._v("Scarica l'avviso di richiamo")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }